import React, { useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import '../components/Navbar.css';
import Logo from "../images/logo-sakola-greens.png";

function CollapsibleExample() {
    const [expanded, setExpanded] = useState(false);

    const handleNavLinkClick = () => {
        setExpanded(false);
    };

    return (
        <Navbar collapseOnSelect expand="lg" className="navbar sticky-top" expanded={expanded}>
            <Container>
                <img src={Logo} height={50} alt="Logo" />
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto"></Nav>
                    <Nav>
                        <Nav.Link as={NavLink} to="/" className='navlink' activeClassName='navlink-active' onClick={handleNavLinkClick}>Home</Nav.Link>
                        <Nav.Link as={NavLink} to="/about" className='navlink' activeClassName='navlink-active' onClick={handleNavLinkClick}>About</Nav.Link>
                        <Nav.Link as={NavLink} to="/news" className='navlink' activeClassName='navlink-active' onClick={handleNavLinkClick}>News</Nav.Link>
                        <Nav.Link as={NavLink} to="/gallery" className='navlink' activeClassName='navlink-active' onClick={handleNavLinkClick}>Gallery</Nav.Link>
                        <Nav.Link as={NavLink} to="/contact" className='navlink' activeClassName='navlink-active' onClick={handleNavLinkClick}>Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default CollapsibleExample;
